.blog,
.blogartikel {
    .ce_gallery {
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            list-style-type: none;
            &.cols_2 {
                li {
                    &.col_first {
                    }
                    &.col_last {
                    }
                }
            }
        }
    }
    .mod_slick_newslist {
        &.slick {
            .slick-container {
                .layout_latest {
                    min-height: 300px;
                }
            }
        }
    }
}

.mod_newslist_infinite_scroll {
    &.blog {
        .layout_latest {
            background-color: $orange;
            margin-bottom: 3px;
            *:not(.image_container) {
                color: #fff;
            }
            figure {
                margin: 0;
                overflow: hidden;
            }
            h2,
            .info,
            .ce_text,
            .more {
                padding: 0 40px;
            }
            h2 {
                padding-top: 40px;
                a {
                    font-size: 1.3rem;
                    line-height: 1.7;
                    font-weight: 700;
                    margin-bottom: 0.9rem;
                }
            }
            .info {
                font-size: 0.95rem;
                font-weight: 300;
            }
            .ce_text {
                margin-bottom: 0;
            }
            .more {
                padding: 0 40px 40px 40px;
                margin-bottom: 0;
                a {
                    font-size: 0.95rem;
                    font-weight: 300;
                }
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                border-right: 1px solid #fff;
            }
            &:nth-of-type(4n + 0) {
                &::after {
                    content: unset;
                }
            }
        }
    }
}
.inf-scr-load-more-btn-container {
    margin: 10px 0 13px;
}

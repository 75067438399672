body,
div,
p,
blockquote,
pre,
code,
ol,
ul,
li,
dl,
dt,
dd,
figure,
table,
th,
td,
form,
fieldset,
legend,
a,
input,
textarea {
  font-family: "Fira Sans", sans-serif;
  color: $font_base;
  font-size: 1rem;
}

img {
  max-width: 100%;
  height: auto;
  &.logo {
    max-width: unset;
    height: unset;
  }
}

.mod_article {
  .ce_text {
    margin-bottom: 50px;
    h2 {
      font-size: 1.3rem;
      font-weight: 700;
      margin-bottom: 0.9rem;
    }
  }
}
.after {
  background-color: #f1f1f2;
}

.contao-cookiebar {
  z-index: 9999999;
  max-height: unset;
}
.euf_overlay {
  z-index: 99999;
}
.euf_overlay__content {
  max-width: 550px;
  padding: 25px;
  top: 30%;
}

.cc-module {
  border: 1px solid #cfcfcf;
  padding: 10px 30px 30px;
}

@media (max-width: 1199px) {
  .leistungen {
    #main {
      .inside {
        > :last-child {
          .row {
            margin-bottom: 0;
            div[class*="col"] {
              margin-bottom: 3px;
              .ce_hyperlink,
              .ce_text {
                min-height: unset;
              }
              &:nth-of-type(2) {
                &::after {
                  border-right: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #header {
    .container {
      max-width: unset;
      img {
        &.logo {
          max-width: 192px;
        }
      }
    }
    .row {
      div[class*="col"] {
        .ce_image {
          margin-bottom: 0;
        }
        &:nth-of-type(2) {
          p {
            display: none;
          }
          #nav-main {
            display: none;
          }
        }
      }
    }
  }
  #nav-open-btn {
    display: block;
    position: absolute;
    right: 0;
  }

  .ce_image {
    margin-bottom: 50px;
  }
  .custom,
  .services,
  .mod_newsreader {
    .ce_image {
      margin-bottom: 0;
    }
  }
  .leistungen {
    #main {
      .inside {
        > :last-child {
          .row {
            margin-bottom: 0;
            .col-md-6 {
              margin-bottom: 3px;
            }
            div[class*="col"] {
              min-height: unset;
              .ce_hyperlink,
              .ce_text {
                min-height: unset;
              }
            }
          }
        }
      }
    }
  }
  .standorte {
    .ce_text {
      margin-top: 30px;
      p {
        font-size: 1.125rem;
      }
    }
  }
  #footer {
    .container {
      max-width: unset;
    }
    .row {
      div[class*="col"] {
        &:first-of-type {
          .ce_text {
            display: block;
            p {
              &::after {
                content: unset;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #header {
    .row {
      div[class*="col"] {
        &:nth-of-type(2) {
          p {
            display: none;
          }
          #nav-main {
            display: none;
          }
        }
      }
    }
  }
  .leistungen {
    #main {
      .inside {
        > :last-child {
          .row {
            div[class*="col"] {
              &::after {
                border-right: unset;
              }
            }
          }
        }
      }
    }
  }
  #footer {
    .row {
      .ce_bs_gridSeparator {
        &.end {
          justify-content: flex-start;
        }
          .nav {
            .nav-link {
              margin-bottom: 0;
              padding: 0 0.5rem 0 0;
            }
          }
        }
      }
    }
}

@media (max-width: 576px) {
  .custom {
    .container {
      .mod_article {
        .ce_image {
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .calltoaction {
    &.ce_text {
      width: 100%;
    }
    &.ce_hyperlink {
      width: 100%;
      position: unset;
    }
  }
  .mod_newsreader {
    .ce_image {
      &.float-left {
        float: none !important;
      }
      &.float-right {
        float: none !important;
      }
    }
  }
  #footer {
    .row {
      div[class*="col"] {
        &:first-of-type {
          .ce_text {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

a {
    &:hover {
        color: $mint;
    }
}
.invisible {
    position: absolute;
}
nav {
    ul {
        margin-bottom: 0;
        li {
            a {
                color: $orange;
                font-weight: 300;
                &:hover {
                    color: $mint;
                }
                span {
                    &:hover {
                        color: $mint;
                    }
                }
            }
            .trail,
            strong {
                font-weight: 300;
                color: $mint;
            }
        }
    }
}
#nav-main {
    .level_1 {
        display: flex;
        position: relative;
        list-style-type: none;
        li {
            list-style-type: none;
            a,
            strong {
                padding-left: 10px;
                padding-right: 10px;
                font-size: 1.125rem;
                &.submenu {
                    cursor: pointer;
                }
                &:last-of-type {
                    padding-right: 0;
                }
            }
            &.submenu {
                &.cols1 {
                    .level_2 {
                        display: none;
                        position: absolute;
                        right: 0;
                        top: 39px;
                        z-index: 9;
                        margin: 0;
                        padding: 0;
                        background-clip: unset;
                        border: none;
                        border-radius: unset;
                        li {
                            float: left;
                            width: 100%;
                            min-width: 240px;
                            a,
                            strong,
                            span {
                                padding: 11px 20px;
                                display: block;
                                color: #fff;
                                font-size: 1.125rem;
                                &:last-of-type {
                                    padding-right: 10px;
                                }
                                &:hover {
                                    color: #fff;
                                    text-decoration: none;
                                }
                            }
                            &:hover,
                            &.active {
                                background-color: $mint;
                            }
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: $orange;
                            z-index: -1;
                        }

                    }
                }
                padding-bottom: 30px;
                &.cols2 {
                    .level_2 {
                        display: none;
                        position: absolute;
                        right: 0;
                        top: 39px;
                        z-index: 9;
                        margin: 0;
                        padding: 0;
                        background-clip: unset;
                        border: none;
                        border-radius: unset;
                        li {
                            float: left;
                            width: 50%;
                            a,
                            strong,
                            span {
                                padding: 11px 20px;
                                display: block;
                                color: #fff;
                                font-size: 1.125rem;
                                &:last-of-type {
                                    padding-right: 10px;
                                }
                                &:hover {
                                    color: #fff;
                                    text-decoration: none;
                                }
                            }
                            &:nth-of-type(2x + 1) {
                                clear: both;
                            }
                            &:hover,
                            &.active {
                                background-color: $mint;
                            }
                        }
                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: $orange;
                            z-index: -1;
                        }
                    }
                }
                &.cols1,
                &.cols2 {
                    &:hover {
                        .level_2 {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
#nav-footer {
    display: inline-block;
}
.ccb_opener {
    margin-left: 15px;
    color: $orange;
}

#nav-open-btn {
    display: none;
    i {
        color: $orange;
    }
}
#navmob {
    z-index: 99;
    &.sr-only {
        height: auto !important;
        clip: unset !important;
    }
}

.mod_mmenu {
    display: none;
    &.mm-menu--opened {
        display: block;
    }
}

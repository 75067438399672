
#header {
    border-bottom: 2px solid $orange;
    height: 77px;
    &.sticky {
        position: sticky;
        top: 0;
        height: 77px;
        margin-top: 0;
        z-index: 999999999999999;
        width: 100%;
        background: white;
    }
    .row {
        padding-top: 16px;
        .address {
            position: absolute;
            top: -4px;
            right: 0;
            z-index: 2;
            font-size: 0.875rem;
            a {
                font-size: 0.875rem;
            }
        }
        .mod_navigation {
            position: absolute;
            right: 0;
            top: 22px;
            z-index: 1;
            .nav-link:last-of-type {
                padding-right: 0;
            }
        }
    }
}

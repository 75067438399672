
.btn {
    border-radius: 0;
    &.btn-primary {
        background-color: $orange;
        border-color: $orange;
        a {
            color: #fff;
            font-size: 0.95rem;
            &:hover {
                color: #fff;
                text-decoration: none;
            }
        }
        &:hover {
            background-color: $mint;
            border-color: $mint;
        }
    }
    &.calltoaction {
        margin-bottom: 4px;
    }
}
.back-to-top {
    position: fixed;
    bottom: 47px;
    right: 60px;
    padding: 1px 10px 1px 10px;
    background-color: $mint;
    border: 1px solid transparent;
    z-index: 9999999;
    a {
        color: #fff;
    }
}

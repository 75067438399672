.je_stellenmarkt,
#leaflet_1,
#leaflet_2,
#leaflet_3{
    width: 100%;
    .cc-module {
        text-align: center;
        p {
            a {
                color: $orange;
            }
        }
        button {
            display: inline-block;
            cursor: pointer;
            padding: 8px 14px;
            font-size: 15px;
            outline: 0;
            border: 1px solid #cfcfcf;
            border-radius: 4px;
            color: #444;
            background: #f5f5f5;
        }
    }
}

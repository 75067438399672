
/* Global Settings */
$white: #ffffff;
$orange: #f7720f;
$mint: #10b9c1;
$font_base: #808184;

.mint {
    background-color: $mint;
}

.ce_form {
    h2 {
        font-weight: 700;
        margin-bottom: 30px;
    }
    .widget-checkbox {
        label {
            a {
                color: $orange;
            }
        }
    }
}
span.mandatory {
    color: #ff0000;
    margin-left: 4px;
}

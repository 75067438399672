
/* fira-sans-300 - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/fira-sans-v8-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Fira Sans Light"), local("FiraSans-Light"),
    url("../fonts/fira-sans-v8-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/fira-sans-v8-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/fira-sans-v8-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/fira-sans-v8-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/fira-sans-v8-latin-300.svg#FiraSans") format("svg"); /* Legacy iOS */
}
/* fira-sans-regular - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fira-sans-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Fira Sans Regular"), local("FiraSans-Regular"),
    url("../fonts/fira-sans-v8-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/fira-sans-v8-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/fira-sans-v8-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/fira-sans-v8-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/fira-sans-v8-latin-regular.svg#FiraSans") format("svg"); /* Legacy iOS */
}
/* fira-sans-700 - latin */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/fira-sans-v8-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Fira Sans Bold"), local("FiraSans-Bold"),
    url("../fonts/fira-sans-v8-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/fira-sans-v8-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/fira-sans-v8-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/fira-sans-v8-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/fira-sans-v8-latin-700.svg#FiraSans") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "RockSolid Icons";
  /* src: url("../fonts/rocksolid-icons.eot"); /* IE9 Compat Modes */
  /* src: url("../fonts/rocksolid-icons.eot?#iefix") format('eot'), /* IE6-IE8 */
  src: url("../fonts/rocksolid-icons.woff2") format('woff2'),
  /* url("../fonts/rocksolid-icons.ttf") format('truetype'), /* Safari, Android, iOS */
  url("../fonts/rocksolid-icons.svg") format('svg');
}
*[data-icon]:before {
  content: attr(data-icon);
  font: 1em/1 "RockSolid Icons";
}

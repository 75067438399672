#footer {
    clear: both;
    background-color: #f1f1f2;
    border-top: 2px solid $orange;
    .row {
        padding-top: 40px;
        padding-bottom: 40px;
        div[class*="col"] {
            .ce_text {
                margin-bottom: 0;
            }
            &:first-of-type {
                align-items: center;
                display: flex;
            }
        }
        .ce_bs_gridSeparator {
            display: flex;
            a {
                &.sm-link {
                    margin-top: 6px;
                    @media (max-width: 767px) {
                        margin: 15px 0;
                    }
                }
                &.instagram:before,
                &.facebook:before {
                    font: 16px/1 "RockSolid Icons";
                    display: inline-block;
                    position: relative;
                    margin-right: 10px;
                    font-size: 1.4rem;
                    color: $orange;
                }
                &.facebook:before {
                    content: "\e075";
                }
                &.instagram:before {
                    content: "\e152";
                }
                &:hover {
                    &:before {
                        color: $mint;
                    }
                }
            }
            &:last-of-type {
                justify-content: flex-end;
                @media (max-width: 767px) {
                    justify-content: flex-start;
                }
            }
            .nav {
                .nav-link {
                    padding-right: 0;
                    padding-top: 0;
                    a, span {
                        font-weight: 400;
                    }
                }
            }
            .ccb_opener {
                display: block;
                padding: 0  0 1rem;
                text-decoration: none;
                @media (max-width: 767px) {
                    padding: 0;
                    margin-left: 0;
                }
            }
        }
    }
}


.service_link {
    display: block;
    position: relative;
    height: 100%;
    padding: 40px 20px;
    background-color: $orange;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s;
    &:hover {
        background-color: $mint;
    }
    a {
        text-decoration: none;
        h2 {
            font-size: 1.5rem;
            font-weight: normal;
            color: $white;
            text-decoration: none;
        }
        p {
            margin-bottom: 0;
            color: $white;
        }
    }
    &.mint {
        background-color: $mint;
    }
}

.leistungen {
    #main {
        .inside {
            > :last-child {
                .row {
                    margin-bottom: 3px;
                    div[class*="col"] {
                        background-color: $orange;
                        &.mint {
                            background-color: $mint;
                        }
                        .ce_hyperlink,
                        .ce_text {
                            margin-bottom: 0;
                            padding-top: 37px;
                            padding-right: 35px;
                            padding-bottom: 35px;
                            padding-left: 35px;
                            min-height: 163px;
                            width: 100%;
                            height: 100%;
                            * {
                                color: #fff;
                            }
                            h2 {
                                font-size: 1.5rem;
                                font-weight: normal;
                            }
                            p {
                                margin-bottom: 0;
                            }
                            a {
                                font-size: 0.875rem;
                                font-weight: 300;
                            }
                            &:not(.ce_hyperlink) {
                                background-color: $mint;
                            }
                        }
                        figure {
                            margin: 0;
                            img {
                                width: 100%;
                            }
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            top: 20%;
                            right: 0;
                            width: 1px;
                            height: 60%;
                            border-right: 1px solid #fff;
                        }
                        &:last-of-type {
                            &:after {
                                content: unset;
                            }
                        }
                    }
                    &:first-of-type {
                        div[class*="col"] {
                            &:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 1px;
                                height: 100%;
                                border-right: 1px solid #fff;
                            }
                        }
                    }
                    &:last-of-type {
                        div[class*="col"] {
                            &:nth-of-type(3) {
                                &:after {
                                    content: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.slidercontent {
    position: absolute;
    top: 20%;
    left: 0;
    width: auto;
    height: auto;
    .inside {
        background: linear-gradient(
                        to right,
                        rgba(247, 114, 15, 0.7) 0%,
                        rgba(247, 114, 15, 0.7) 100%,
                        rgba(0, 0, 0, 0) 100%,
                        rgba(0, 0, 0, 0) 100%
        );
        filter: saturate(100%);
        .ce_text {
            text-transform: uppercase;
            font-size: 1.8rem;
            width: 100%;
            h2 {
                font-size: 2.95rem;
                font-weight: 700;
                color: #fff;
                padding: 50px;
            }
            p {
                color: #fff;
            }
        }
    }
}

.notfallbox {
    position: absolute;
    bottom: -286px;
    transition: all 1s;
    right: 15px;
    width: 570px;
    height: 330px;
    cursor: pointer;
    opacity: 0.9;
    z-index: 99999;
    +.container,
    +.container-fluid {
        position: relative;
        z-index: 99999;
    }
    &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        top: 15px;
        right: 15px;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 11px solid #fff;
        z-index: 9;
    }
    .ce_text {
        h2 {
            position: relative;
            font-size: 1.8rem!important;
            font-weight: 700;
            text-align: center;
            color: #fff;
            background-color: red;
            padding: 5px;
        }
        p {
            padding: 10px 20px;
            color: #565656;
            font-size: 1.125rem;
        }
        figure {
            text-align: center;
            width: 50%;
        }
    }
    &::before {
        background-color: #fff;
    }
    &:hover {
        bottom: 0;
        opacity: 1;
        &:after {
            transform: rotate(180deg);
        }
    }
}

.reuther {
    .notfallbox {
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #fff;
    }
}
.bernauer {
    .notfallbox {
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #fff;
    }
}


@media (max-width: 1199px) {
    .slidercontent {
        .inside {
            .ce_text {
                h2 {
                    font-size: 2.5rem;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .slidercontent {
        .inside {
            .ce_text {
                h2 {
                    font-size: 2.25rem;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .slidercontent {
        top: 10%;
        .inside {
            .ce_text {
                h2 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .slidercontent {
        top: 10%;
    }
    .notfallbox {
        position: unset;
        width: 100%;
        height: 310px;
        &:after {
            content: unset;
        }
        .ce_text {
            h2 {
                font-size: 1.5rem;
            }
            p {
                font-size: 1rem;
            }
        }
        &:hover {
            &:after {
                transform: unset;
            }
        }
    }
}

@media (max-width: 576px) {
    .slidercontent {
        top: 10%;
        .inside {
            .ce_text {
                h2 {
                    font-size: 1.45rem;
                }
            }
        }
    }
    .notfallbox {
        .ce_text {
            h2 {
                font-size: 1.4rem!important;
            }
            p {
                font-size: 1rem;
            }
        }
    }
}

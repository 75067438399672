
.before {
    .custom {
        .container-fluid {
            width: 100%;
            position: relative;
            padding-left: 0 !important;
            padding-right: 0 !important;
            overflow: hidden;
            .row {
                .mod_article {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                    position: relative;
                    .slick {
                        margin-left: 0;
                        margin-right: 0;
                        padding: 0;
                        &.header {
                            .slick-list {
                                margin-left: -15px;
                                margin-right: -15px;
                                .slick-dots {
                                    position: absolute;
                                    top: 360px;
                                    left: 30px;
                                }
                            }
                            .slick-slide {
                                width: 100%;
                            }
                            .ce_image {
                                width: 100%;
                                .image_container {
                                    width: 100%;
                                    margin: 0;
                                    picture {
                                        width: 100%;
                                        img {
                                            width: 100%;
                                            height: auto;
                                        }
                                    }
                                }
                            }
                            .slick-dots {
                                bottom: 45px;
                                left: calc(50% - 35px);
                                z-index: 9999;
                            }
                        }
                    }
                }
            }
        }
    }
}

.slick {
    &.fahrzeugklassen.startseite {
        .slick-container {
            z-index: 99999;
            .slick-list {
                overflow: hidden;
                height: fit-content!important;
                .slick-track {
                    display: block;
                    flex-wrap: wrap;
                    margin-right: 0;
                    margin-left: 0;
                    .slick-slide {
                        width: auto;
                    }
                }
            }
            .slick-arrow {
                background-color: #fff;
                &.slick-prev,
                &.slick-next {
                    width: 24px;
                    height: 34px;
                    background-color: #f1f1f2;
                    z-index: 999;
                    top: unset;
                    bottom:-47px;
                }
                &.slick-prev {
                    left: 0;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 4px;
                        left: 5px;
                        border-top: 13px solid transparent;
                        border-bottom: 13px solid transparent;
                        border-right: 13px solid $orange;
                        opacity: 1;
                    }
                }
                &.slick-next {
                    right: 0;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 4px;
                        right: 5px;
                        border-top: 13px solid transparent;
                        border-bottom: 13px solid transparent;
                        border-left: 13px solid $orange;
                        opacity: 1;
                    }
                }
            }
            .slick-dots {
                text-align: center;
                width: auto;
                left: calc(50% - 50px);
                bottom: -25px;
                li {
                    margin: 0;
                    button {
                        &:before {
                            content: "";
                            background-color: $orange;
                            width: 12px;
                            height: 12px;
                            border-radius: 6px;
                            opacity: 1;
                        }
                    }
                    &.slick-active {
                        button {
                            &:before {
                                content: "";
                                background-color: $mint;
                                width: 12px;
                                height: 12px;
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ce_slick-slider {
    .slick-container {
        .slick-list {
            .slick-track {
                .slick-slide {
                    .image_container {
                        margin: 0 10px;
                    }
                }
            }
        }
    }
}

.mod_slick_newslist1 {
    &.slick {
        .slick-container {
            background-color: #f1f1f2;
            .slick-arrow {
                &.slick-prev,
                &.slick-next {
                    background-color: #fff;
                }
                &.slick-prev {
                    left: -30px;
                }
                &.slick-next {
                    right: -30px;
                }
            }
            .layout_latest {
                padding-top: 30px;
                min-height: 150px;
                .headline {
                    h2 {
                        line-height: 1.6;
                        a {
                            font-size: 1.125rem;
                            font-weight: 700;
                        }
                    }
                    .info {
                        font-size: 0.95rem;
                    }
                }
                .ce_text {
                }
                .more {
                    button {
                        position: absolute;
                        bottom: 10px;
                        border-color: $orange;
                        background-color: $orange;
                        a {
                            color: #fff;
                            font-size: 0.95rem;
                        }
                        &:hover {
                            background-color: $mint;
                            border-color: $mint;
                        }
                    }
                }
            }
        }
    }
}

.slick:not(.fahrzeugklassen) {
    .slick-dotted {
        margin-bottom: 0;
    }
    .slick-arrow {
        background-color: #fff;
        &.slick-prev,
        &.slick-next {
            width: 24px;
            height: 34px;
            background-color: #f1f1f2;
            z-index: 999;
        }
        &.slick-prev {
            left: 0;
            &::before {
                content: "";
                position: absolute;
                top: 4px;
                left: 5px;
                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;
                border-right: 13px solid $orange;
                opacity: 1;
            }
        }
        &.slick-next {
            right: 0;
            &::before {
                content: "";
                position: absolute;
                top: 4px;
                right: 5px;
                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;
                border-left: 13px solid $orange;
                opacity: 1;
            }
        }
    }
    .slick-dots {
        text-align: left;
        width: auto;
        li {
            margin: 0;
            button {
                &:before {
                    content: "";
                    background-color: #fff;
                    width: 12px;
                    height: 12px;
                    border-radius: 6px;
                    opacity: 1;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        content: "";
                        background-color: $orange;
                        width: 12px;
                        height: 12px;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .custom {
        .container-fluid {
            .slick {
                .slick-dots {
                    width: auto;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .ce_slick-slider {
        margin-bottom: 50px;
    }
}

@media (max-width: 640px) {
    .custom {
        .container-fluid {
            .slick {
                .slick-dots {
                    bottom: 15px!important;
                }
            }
        }
    }
}

.fleetbox-ext {
    &.horicontal {
        margin-bottom: 25px;
        a {
            display: flex;
        }
        .base,
        .details {
            width: 50%;
        }
        .details {
            padding: 30px 40px;
            background-color: #f3efef;
            .description {
                .ext_text {
                    text-align: center;
                }
            }
        }
        .ce_headline,
        .base_text {
            text-align: center;
        }
    }
    &.vertical {
        width: 100%;
        .details {
            padding: 30px 30px 60px 30px;
            .description {
                .ext_text {
                    text-align: center;
                }
            }
        }
    }
    &.orange {
        background-color: $orange;
    }
    &.mint {
        background-color: $mint;
    }
    &.no-border {
        .ce_text {
            &:after {
                content: unset;
            }
        }
    }
    a {
        text-decoration: none;
        cursor: pointer;
        position: relative;
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        border-left: 1px solid #fff;
        border-right: unset;
    }
    .base {
        .ce_headline {
            padding: 26px 30px 12px 30px;
            margin-bottom: 0;
            color: #fff;
            font-size: 1.8rem;
        }
        .base_text {
            position: unset;
            padding-left: 30px;
            padding-right: 30px;
            margin-bottom: 0;
            p {
                color: #fff;
                font-size: 1.125rem;
                font-weight: 300;
                min-height: 54px;
                margin-bottom: 0;
            }
        }
        figure {
            padding-top: 40px;
            padding-bottom: 20px;
            picture {
                img {
                    position: relative;
                    left: 50%;
                    transform: translatex(-50%);
                }
            }

        }
    }
    .details {
        background-color: $white;
        padding: 10px 10px 0 10px;
        figure {
            padding-top: 25px;
            padding-bottom: 20px;
            img {
                position: relative;
                left: 50%;
                transform: translatex(-50%);
            }
        }
        .description {
            .ext_text {
                position: unset;
                margin-bottom: 35px;
                p {
                    color: $font_base;
                    font-size: 1.125rem;
                    font-weight: 300;
                    min-height: 54px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.slick {
    &.fahrzeugklassen.startseite {
        .slick-container {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        &.slick-active,
                        &.slick-current {
                            .fleetbox-ext {
                                &::after {
                                    border-left: 1px solid #fff;
                                    border-right: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.fleetdetails {
    margin-bottom: 25px;
    .ce_gallery {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
                margin-bottom: 40px;
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .ce_slick-slider {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .ce_headline {
        margin-bottom: 10px;
        color: $font_base;
        font-size: 1.8rem;
        img {
            vertical-align: middle;
            margin-left: 25px;
        }
        span {
            position: absolute;
            top: 3px;
            color: $orange;
            font-size: 0.875rem;
        }
    }
    .ce_subheadline {
        font-size: 0.875rem;
        font-weight: 700;
    }
    .ce_text {
        ul {
            margin: 30px 0;
            padding-left: 15px;
            li {
                font-size: 1rem;
            }
        }
    }
    .properties {
        margin-top: 40px;
        padding: 37px 30px;
        background-color: #f1f1f2;
        .property {
            border-top: 1px solid #808184;
            padding: 1rem 0;
            .property_name {
                position: relative;
                top: -25px;
                left: 30px;
                display: block;
            }
            .property_value {
                font-weight: 700;
                position: relative;
                top: -25px;
                left: 30px;
                display: block;
                margin-bottom: -25px;
            }
            &:last-of-type {
                border-bottom: 1px solid #808184;
            }
        }
        .col-md-12 {
            &:first-of-type {
                .property {
                    &:last-of-type {
                        border-bottom: unset;
                    }
                }
            }
        }
    }
    .calltoaction {
        &.ce_hyperlink {
            width: 100%;
            margin-top: 1rem;
        }
    }
    .ce_hyperlink {
        &.nav-fleet {
            margin-top: 30px;
            margin-bottom: 40px;
            a {
                color: $orange;
            }
        }
    }
}


@media (min-width: 1px) {
    .fleetbox-ext {
        &.horicontal {
            a {
                display: block;
                .base,
                .details {
                    width: 100%;
                }
                .details {
                    .description {
                        padding: 20px;
                        .ext_text {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        &.vertical {
            .ce_headline {
                text-align: center;
                font-size: 1.5rem;
            }
            .base {
                .base_text {
                    text-align: center;
                    font-size: 1.125rem;
                    p {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .fleetbox-ext {
        &.vertical {
            &:after {
                border-right: unset;
            }
        }
        &.horicontal {
            a {
                display: block;
                .base,
                .details {
                    width: 100%;
                }
                .details {
                    .description {
                        padding: 20px;
                        .ext_text {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .fleetbox-ext {
        &.horicontal {
            a {
                display: flex;
            }
            .base,
            .details {
                width: 50%;
            }
        }
    }
}

@media (min-width: 991px) {
    .fleetdetails {
        &.container {
        }
        +.container {
        }
    }
}

@media (min-width: 1199px) {
    .fleetbox-ext {
        &.vertical {
            .ce_headline {
                font-size: 1.8rem;
            }
            .base .base_text {
                p {
                    font-size: 1rem;
                }
                font-size: 1.125rem;
            }
        }
    }
}

h1 {
    font-family: "Fira Sans", sans-serif;
    color: $font_base;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 2.4rem;
}
h2 {
    font-family: "Fira Sans", sans-serif;
    color: $font_base;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 2rem;
}
h3 {
    font-family: "Fira Sans", sans-serif;
    color: $font_base;
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

body {
    div#article-21:after {
        content: "";
        position: absolute;
        top: 0px;
        width: 100%;
        height: 309px;
        background-color: $orange;
        z-index: -1;
    }
    .container-fluid {
        #main {
            width: 100%;
            position: relative;
            .inside {
                > *:first-child {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
    &:not(.index):not(.blog) {
        .container-fluid {
            #main {
                width: 100%;
                position: relative;
                .inside {
                    padding-top: 70px;
                    padding-bottom: 70px;
                    > *:first-child {
                        padding-top: 30px;
                        padding-bottom: 30px;
                    }
                }
            }
        }
    }
}
